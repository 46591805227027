import PropTypes from 'prop-types'
import React from 'react'

import bio_img from '../images/bio.jpg'

//import { StaticImage } from "gatsby-plugin-image"

class Main extends React.Component {
    render() {
        let close = (
            <div
                className="close"
                onClick={() => {
                    this.props.onCloseArticle()
                }}
            ></div>
        )

        return (
            <div
                ref={this.props.setWrapperRef}
                id="main"
                style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
            >
                <article
                    id="cv"
                    className={`${this.props.article === 'CV' ? 'active' : ''} ${
                        this.props.articleTimeout ? 'timeout' : ''
                        }`}
                    style={{ display: 'none' }}
                >
                    <h1 className="major">Éamonn Ó Cearnaigh</h1>

                    <ul className="icons">

                        <li>
                            <a
                                href="https://www.linkedin.com/in/eamonn-ocearnaigh"
                                className="icon fab fa-linkedin"

                            >
                                <span className="label">LinkedIn</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://github.com/EamonnOCearnaigh"
                                className="icon fa-github"
                            >
                                <span className="label">GitHub</span>
                            </a>
                        </li>
                    </ul>

                    <h2>Profile</h2>
                    <p>
                    Highly ambitious and deeply passionate Data Science Integrated Masters (MSci) student at City, University of London. 
                    Machine Learning, Bioinformatics, and Software Engineering experience. 
                    Team player with excellent interpersonal skills but can also thrive working individually. 
                    Particularly interested in altruistic projects.
                    </p>

                    <h2>Recent Experience</h2>

                    <h3>Trainee Software Engineer, VisualSilicon, London/Cambridge</h3>
                    <h4>November 2021 - May 2022</h4>
                    <ul>
                        <li>Completed training in Embedded Systems programming</li>
                        <li>Contributed to app-development research and investment meetings</li>
                        <li>Worked remotely from London, and occasionally from an office in Cambridge</li>
                    </ul>

                    <h3>Bioinformatics Placement Student, The Institute of Cancer Research, London</h3>
                    <h4>July 2020 - September 2021</h4>
                    <ul>
                        <li>Programming and assisting Functional Proteomics Team, led by Professor Jyoti Choudhary</li>
                        <li>Completing tasks supervised by Dr James Wright, the team Bioinformatician</li>
                        <li>Collaborating with academics at Harvard Medical School and the European Bioinformatics Institute</li>
                        <li>Expanding the functionality of a proteogenomic mapping tool in Java.  Allowing researchers to analyse a vastly greater range of input data with the extended software program</li>
                        <li>First author of a research publication detailing the updates made to the software, PoGo/Pepgenome</li>
                        <li>Assisting with the building and running of High Performance Computing pipelines processing cell line data</li>
                        <li>Through interaction with HPC: Learning Linux, shell scripting, Slurm, NextFlow</li>
                        <li>Extensive self-study prior to project covering the basics of Genomics, Transcriptomics, Proteomics and general Molecular Biology as this was my introduction to the field</li>
                    </ul>

                    <h3>Machine Learning Intern, PhysiGo, London</h3>
                    <h4>May 2020 - July 2020</h4>
                    <ul>
                        <li>Assisting CTO of wearable tech start-up ahead of product launch</li>
                        <li>Projects involved evolutionary algorithms, neural networks, and data visualisation, all using Python</li>
                    </ul>

                    <h2>Education</h2>
                    <h3>Data Science Integrated Master's Degree (MSci with Hons)</h3>
                    <h3>City, University of London, UK</h3>
                    <h4>2018 - 2023</h4>
                    
                    <h3>Yearly Average Grades and Notable Modules</h3>
          
                        <p><b>Stage 1 (1st):</b> Discrete Mathematics (90.8%), Intro. to Algorithms (85.1%), Systems Architecture (83.2%)</p>

                        <p><b>Stage 2 (1st):</b> C++ Programming (98.3%), Computer Networks (92.0%), Data Structures & Algorithms (91.6%)</p>

                        <p><b>Placement (1st):</b> Bioinformatics Project with the Institute of Cancer Research</p>

                        <p><b>Stage 3 (1st):</b> Intro to AI (78.0%), Programming & Maths for AI (71.75%)</p>

                        <p><b>Stage 4 (Autumn 2022): </b> Computational Cognitive Systems, Neural Computing, Final Project w/ the Institute of Cancer Research</p>


                    <h2>Recent Projects</h2>

                    <h3>Facial Emotion Recognition</h3>
                    <ul>
                        <li>Solo Computer Vision University Project</li>
                        <li>Designing and deploying pipelines involving support vector machines, multi-layer perceptrons, and convolutional neural networks</li>
                        <li>Image classification to identify emotion via images of facial expressions using Python libraries</li>
                    </ul>

                    <h3>Neural Networks for Classification</h3>
                    <ul>
                        <li>Team University Project</li>
                        <li>Designing and deploying ANNs using NumPy & PyTorch</li>
                    </ul>

                    <h3>Airbnb Price Prediction</h3>
                    <ul>
                        <li>Team University Project</li>
                        <li>Extensive cleaning of real-world public data</li>
                        <li>Analysing the biggest price influences on Airbnb rental prices using Random Forest and Keras Neural Network models</li>
                    </ul>

                    <h3>Proteogenomic Mapping</h3>
                    <ul>
                        <li>Bioinformatics Placement Project at ICR</li>
                        <li>Expanding the functionality of a proteogenomic Java-based mapping tool, PoGo</li>
                        <li>The algorithm analyses peptide, protein, and genome data and processes it in order to map peptides to their genomic origins</li>
                    </ul>

                    <h2>Interests</h2>
                    <ul>
                        <li><b>Science & Tech:</b>  Passionate about exploring how things work, and how research and innovation can improve lives</li>
                        <li><b>Travel & Culture:</b>  Three backpacking trips in 2018, 2019 and 2022, visiting a total of 22 European cities across 13 countries</li>
                        <li><b>Languages:</b>  Intermediate in Irish and French, beginner in Dutch and German</li>
                    </ul>

                    {close}
                </article>

                <article
                    id="projects"
                    className={`${this.props.article === 'Projects' ? 'active' : ''} ${
                        this.props.articleTimeout ? 'timeout' : ''
                        }`}
                    style={{ display: 'none' }}
                >
                    <h2 className="major">Projects</h2>

                    <h3>Ongoing Projects</h3> 

                    <h4>Module TBD</h4>
                    <ul>
                        <li>...</li>

                        <li>...</li>

                        <li>...</li>
                    </ul>

                    <h4>Module TBD</h4>
                    <ul>
                        <li>...</li>

                        <li>...</li>

                        <li>...</li>
                    </ul>

                    <h4>Module TBD</h4>
                    <ul>
                        <li>...</li>

                        <li>...</li>

                        <li>...</li>
                    </ul>
                    
                    <h3>Recently Completed Projects</h3> 

                    <h4>PROTEOGENOMIC MAPPING</h4>
                    <h5>Bioinformatics Placement Project at ICR</h5>
                    <ul>
                        <li>Expanding the functionality of a proteogenomic Java-based mapping tool, PoGo</li>

                        <li>The algorithm analyses peptide, protein, and genome data and processes it in order to map peptides to their genomic origins</li>
                        
                        <li><a href="https://github.com/EamonnOCearnaigh/pgatk">GitHub Repo</a></li>
                    </ul>

                    <h4>FACIAL EMOTION RECOGNITION</h4>
                    <h5>Solo Computer Vision University Project</h5>
                    <ul>
                        <li>Designing and deploying pipelines involving support vector machines, multi-layer perceptrons, and convolutional neural networks</li>

                        <li>Image classification to identify emotion via images of facial expressions using Python libraries</li>

                        <li>...</li>
                    </ul>

                    <h4>NEURAL NETWORKS FOR CLASSIFICATION</h4>
                    <h5>Team University Project</h5>
                    <ul>
                        <li>Designing and deploying ANNs using NumPy & PyTorch</li>

                        <li>...</li>

                        <li>...</li>
                    </ul>


                    {close}
                </article>

                <article
                    id="contact"
                    className={`${this.props.article === 'Contact' ? 'active' : ''} ${
                        this.props.articleTimeout ? 'timeout' : ''
                        }`}
                    style={{ display: 'none' }}
                >
                    <h2 className="major">Contact</h2>

                    <h3>Éamonn Ó Cearnaigh</h3>
                    <h4>eamonn.ocearnaigh@outlook.com</h4>
                    <p>Currently based in central London, UK.</p>
                    <ul className="icons">

                        <li>
                            <a
                                href="https://www.linkedin.com/in/eamonn-ocearnaigh"
                                className="icon fab fa-linkedin"

                            >
                                <span className="label">LinkedIn</span>
                            </a>

                        </li>
                        <li>
                            <a
                                href="https://github.com/EamonnOCearnaigh"
                                className="icon fa-github"
                            >
                                <span className="label">GitHub</span>
                            </a>
                        </li>
                    </ul>
                    {close}
                </article>

                <article
                    id="bio"
                    className={`${this.props.article === 'Bio' ? 'active' : ''} ${
                        this.props.articleTimeout ? 'timeout' : ''
                        }`}
                    style={{ display: 'none' }}
                >
                    <h2 className="major">Bio</h2>
                    
                    <span className="image main">
                        <img src={bio_img} alt="" />
                    </span>

                    <p>I grew up in Ireland and moved to London in 2018 to undertake a Data Science degree.</p>

                    <h3>Research Interests</h3>

                    <p>...</p>

                    {close}
                </article>


            </div>
        )
    }
}

Main.propTypes = {
    route: PropTypes.object,
    article: PropTypes.string,
    articleTimeout: PropTypes.bool,
    onCloseArticle: PropTypes.func,
    timeout: PropTypes.bool,
    setWrapperRef: PropTypes.func.isRequired,
}

export default Main
